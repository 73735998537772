@import './colors';

.ContentPanel {
  background-color: $green_faded;
  border-radius: 6px;
  padding: 2px 30px 12px;
  margin: 32px auto;
  max-width: 700px;
  box-sizing: border-box;

  h3 {
    font-size: 36px;
  }

  img {
    display: block;
    margin: 14px auto;
    height: auto;
    max-width: 100%;

    &.inline {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  hr {
    border: 1px solid $green_dark;
    margin: 22px 6px 12px;
  }

  .centered { text-align: center; }
}
