@import 'components/shared/colors';

body {
  background-color: $green_black;
  background-image: url('/assets/background.png');
   background-repeat: repeat;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;

  * { transition: background-color .075s ease-out; }

  h1, h2, h3, h4 {
    font-family: 'Lora', serif;
    font-weight: normal;
    line-height: 1.4;
    color: $soft_black;
    margin: 20px 0 8px;
  }

  h1 {
    text-align: center;
    font-size: 52px;
    margin: 10px 0 18px;
    color: $green_dark;
  }

  p, li, strong, button, input {
    font-family: 'PT Sans', sans-serif;
    font-weight: normal;
    line-height: 1.4;
    color: $soft_black;
  }

  strong { font-weight: bold; }
  em { font-style: italic; }

  ul, ol {
    margin: 16px 0;

    li {
      margin: 6px 0 12px;
      padding: 0 4px;
    }
  }

  button {
    cursor: pointer;
  }

  a {
    color: $rust;
    &:hover { color: $rose; }
  }
}
