@import './shared/colors';

header.Header {
  text-align: center;
  width: 100%;

  .navigation {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;

    background-color: $green_black;

    a {
      display: block;
      font-family: 'Lora';
      text-decoration: none;
      font-size: 18px;
      font-weight: 500;
      color: $green_diluted;
      padding: 6px 0;
      width: 220px;

      cursor: pointer;

      &:hover {
        color: white;
        background-color: $green_grass;
      }

      &.current {
        color: white;
        font-weight: 700;
        // cursor: auto;

        // &:hover {
        //   background-color: transparent;
        // }
      }

      .vertical-center {
      }
    }
  }

  img {
    height: auto;
    max-width: 100%;
    margin-top: 8px;
  }

  a.temporary-promo {
    display: block;
    text-decoration: none;
    background-color: $green_faded;
    font-size: 24px;
    width: 100%;
    max-width: 700px;
    border-radius: 6px;
    box-sizing: border-box;
    margin: 20px auto 10px;
    padding: 18px 10px;
  }
}
