
$green_diluted: #e3f0c7;
$green_tanned: #abc27a;
$green_faded: #8db54f;
$green_aged: #6e8d5a;
$green_grass: #508230;
$green_moss: #2f6721;
$green_dark: #13370a;
$green_black: #0C100A;

$dun: #c2c787;

$mustard_light: #c8c65c;
$mustard_faded: #c1ba57;
$mustard_border_light: #afa63d;
$mustard_border: #9f9637;

$soft_black: rgb(10, 10, 10);

$rust: #8f0017;
$rust_light: #b12038;

$rose: #7b2153;
$crushed_velvet: #593240;

@mixin mustard_button {
  border: 3px solid $mustard_border;
  background-color: $mustard_faded;

  &:hover {
    border-color: $mustard_border_light;
    background-color: $mustard_light;
  }
}

@mixin shadow {
  box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.15), 0 1px 2px 0 rgba(60, 64, 67, 0.3);
}
