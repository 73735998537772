@import './shared/colors';

footer.Footer {

  .ContentPanel {
    padding-bottom: 16px;
  }

  img.pride-flag {
    display: inline-block;
    width: 20px;
    height: 14px;
    border: 1px solid #615f5f;
    border-radius: 2px;
    margin: 0 6px;

    transform: translateY(3px);
  }

  .newsletter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  img.twitter-link {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    transform: translateY(7px);
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
    margin: 10px 0;
  }

  code {
    font-size: 14px;
    font-weight: bold;
  }

  form {
    display: inline-block;

    #tlemail {
      background-color: lightgoldenrodyellow;
      padding: 4px 6px;
      margin: 0 4px 0 8px;
      border: 1px solid #173f17;
    }

    #submitbutton {
      color: white;
      background-color: #8f0017;
      border: 1px solid #173f17;
      padding: 4px 8px;
    }
  }
}
